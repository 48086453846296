<template>
  <div class="Disclaimers">
    <div class="tabar">
      <span>您所在的位置：&nbsp;&nbsp;</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>免责声明</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="dl-content">
      <div class="dl-title">免责声明</div>
      <div class="dl-text1">
        为了更好对接农民和服务组织供需信息，化解信息不对称的困境，平台覆盖粮棉油糖、果树茶叶、蔬菜花卉、畜牧养殖、
        渔业养殖五大领域，将服务组织提供的农业信息服务、
        农资供应服务、农事生产服务、农机租赁与维修服务、废弃物回收利用服务、农产品加工采购服务等内容以产品形式汇聚在平台上，
        小农户和新型农业生产主体便捷化利用手机和电脑直接与服务组织进行对接和交流，消除小农户与服务组织的信息鸿沟，
        促进农业服务资源在合理区域流动，全面提高农业社会化服务效率。
      </div>
      <div class="dl-text1">
        <div class="dl-text2">免责条款:</div>
        <div>
          本网站致力于提供公益性的供需信息，但所有信息仅供参考，不保证信息的绝对正确性和完整性，且不对因信息的不正确或遗漏导致的任何损失或损害承担责任。对于通过本站所提供信息发生的买卖行为，需由供需双方自行审慎确认买卖事宜，
          对于交易中发生的损失由交易双方自行承担，本网站概不负责。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.Disclaimers {
  width: 1020px;
  margin: 20px auto;
  .tabar {
    height: 52px;
    margin: 0 auto 20px;
    color: #555;
    font-size: 12px;
    display: flex;
    align-items: center;
    /deep/ .el-breadcrumb {
      font-size: 12px;
      color: #555;
    }
  }
  .dl-content {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    height: 500px;
    .dl-title {
      font-size: 26px;
      color: #333333;
      text-align: center;
      height: 45px;
      border-bottom: 1px solid #ddd;
    }
    .dl-text1 {
      margin-top: 28px;
      font-size: 14px;
      color: #666666;
      line-height: 25px;
      text-indent: 2em;
      display: flex;
      flex-wrap: wrap;
    }
    .dl-text2 {
      color: #666666;
      font-weight: bold;
    }
  }
}
</style>